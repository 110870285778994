@font-face {
    font-family: 'Comfortaa';

    src: local('MyFont'), url(./fonts/Comfortaa-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                        'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'Cy Grotesk';
    font-style: normal;

    font-weight: 800;
    font-size: 28px;
    src: local('Cy'), url(./fonts/test.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                      'embedded-opentype', and 'svg' */
}

a {
    &:active {
        outline: 0;
        border: none;
    }

    &:focus {
        outline: 0;
        border: none;
    }
}

button {
    &:active {
        outline: 0;
        border: none;
    }

    &:focus {
        outline: 0;
        border: none;
    }
}


body {
    h1 {
        text-transform: uppercase;
    }
    overflow-x: auto !important;
    margin: 0;
    font-family: -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

  ::-webkit-scrollbar {
    width: 8px; /* ширина полосы прокрутки */
    height: 10px;
  }

 
  /* Стили для трека (фон полосы прокрутки) */
  ::-webkit-scrollbar-track {
    background-color: #000000; /* цвет фона полосы прокрутки в светлой теме */
  }
  
  /* Стили для ручки (перемещаемой части полосы прокрутки) */
  ::-webkit-scrollbar-thumb {
    background-color: #161616; /* цвет ручки в светлой теме */
  }
  
  /* Стили для полосы прокрутки в активном состоянии (наведение) */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #202020; /* цвет ручки при наведении в светлой теме */
  }
  
  /* Стили для трека в активном состоянии (наведение) */
  ::-webkit-scrollbar-track:hover {
    background-color: #000000; /* цвет фона полосы прокрутки при наведении в светлой теме */
  }
  ::-webkit-scrollbar-corner {
    background-color: #000000; /* Цвет уголка в темной теме */
  }
  

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}